import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { TransportationOnlyPopoverComponent } from '@cosCoreFeatures/@cos/cos-popover/components/transportation-only-popover/transportation-only-popover.component';
import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';

@NgModule({
  declarations: [TransportationOnlyPopoverComponent],
  imports: [CommonModule, BaseModule, OverlayModule, CosPopoverModule],
  providers: [TransportationOnlyPopoverComponent],
  exports: [TransportationOnlyPopoverComponent],
})
export class TransportationOnlyPopoverModule {}
