import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Popover } from '@cosCoreFeatures/@cos/cos-popover/services/popover.service';

@Component({
  selector: 'app-transportation-only-popover',
  templateUrl: './transportation-only-popover.component.html',
  styleUrls: ['./transportation-only-popover.component.scss'],
})
export class TransportationOnlyPopoverComponent {
  public constructor(
    private popover: Popover,
    private translateSvc: TranslateService,
  ) {}

  public showPopover(event: Event) {
    event.stopPropagation();

    this.translateSvc
      .get('transport.transport-only-popover.message', {
        messageTemplate: '<h3><strong>{title}</strong></h3><ul><li>{info-1}</li><li>{info-2}</li><li>{info-3}</li><li>{info-4}</li><li>{info-5}</li></ul>',
      })
      .subscribe(translatedTemplate => {
        this.popover.open({
          content: translatedTemplate,
          origin: event.currentTarget as HTMLElement,
        });
      });
  }
}
