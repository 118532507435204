import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppEnvironment, IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';
import { HttpClient } from '@angular/common/http';
import { CustomHttpClient } from '@caronsale/frontend-services';

export interface IBiServiceResult<T> {
  count: number;
  results: T[];
}

export interface IBuyerKpiData {
  bid_development: number;
  business_name: string;
  buyer_id: number;
  buyer_uuid: string;
  city: string;
  complaint_rate_last_12_months: number;
  country_name: string;
  created_at: string;
  deleted_at: null;
  email_address: string;
  first_login: string;
  first_purchase: string;
  full_name: string;
  gmv_last_12_months: number;
  id: string;
  is_bidding_prohibited: boolean;
  last_bid: string;
  last_login: string;
  last_purchase: string;
  last_view: string;
  login_development: number;
  nps_category_development: number;
  nps_score: number;
  nps_comment: string;
  nps_participated_at: string;
  nr_bids_last_12_months: number;
  nr_bids_last_30_days: number;
  nr_complaints_last_12_months: number;
  nr_logins_last_12_months: number;
  nr_logins_last_30_days: number;
  nr_purchases_all_time: number;
  nr_purchases_last_12_months: number;
  nr_purchases_last_30_days: number;
  nr_return_all_time: number;
  nr_views_last_12_months: number;
  nr_views_last_30_days: number;
  nr_waiting_for_payment: number;
  nr_waiting_for_pickup: number;
  purchase_development: number;
  rfm_score: number;
  updated_at: string;
  view_development: number;
  days_since_last_view: number;
  days_since_last_bid: number;
  days_since_last_purchase: number;
  avg_payment_duration: number;
  avg_rating_last_6_months: number;
  nr_ratings_last_6_months: number;
  fully_registered_at: string;
  share_of_transportation: number;
}

@Injectable()
export class CosBiServiceClientService {
  public constructor(
    @Inject(CustomHttpClient) private httpService: HttpClient,
    @Inject(AppEnvironment) private environment: IAppEnvironment,
  ) {}

  public getBuyerKpis(buyerUuid: string): Observable<IBiServiceResult<IBuyerKpiData>> {
    if (this.environment.biServiceAuthToken) {
      return this.httpService.get(`https://caronsale-bi-service-prod.herokuapp.com/internal/kpi/latest/?filter={"buyerUuids":["${buyerUuid}"]}`, {
        headers: {
          Authorization: `Bearer ${this.environment.biServiceAuthToken}`,
        },
      }) as Observable<IBiServiceResult<IBuyerKpiData>>;
    } else {
      // If token is not set, use static test data:

      return Observable.create(observer => {
        observer.next({
          count: 1,
          next: null,
          previous: null,
          results: [
            {
              id: '581b9b4f-8fe2-4e6f-bdd3-9af1dabdec12',
              created_at: '2020-09-04T09:30:26.294562Z',
              updated_at: '2020-09-04T09:30:26.294576Z',
              deleted_at: null,
              buyer_id: 13772,
              buyer_uuid: '6065c6db-4de4-400c-92a2-7ecc8a901d1c',
              business_name: 'Autodienst Blüthgen',
              full_name: 'Frank Blüthgen',
              email_address: 'fbluethgen@hotmail.com',
              country_name: 'Germany',
              city: 'Zossen',
              is_bidding_prohibited: false,
              rfm_score: 444.0,
              last_altered: '2020-09-04T11:03:24.370000Z',
              first_login: '2020-07-01',
              last_login: '2020-09-04',
              nr_logins_last_12_months: 16,
              nr_logins_last_30_days: 13,
              login_development: 6.5,
              days_since_last_login: 0,
              first_view: '2020-07-01',
              last_view: '2020-09-04',
              nr_views_last_12_months: 265,
              nr_views_last_30_days: 250,
              view_development: 20.83,
              days_since_last_view: 0,
              first_bid: '2020-07-01',
              last_bid: '2020-09-04',
              nr_bids_last_12_months: 247,
              nr_bids_last_30_days: 238,
              bid_development: 29.75,
              days_since_last_bid: 0,
              first_purchase: '2020-08-10',
              last_purchase: '2020-08-31',
              nr_purchases_last_12_months: 8,
              gmv_last_12_months: 60450,
              nr_purchases_last_30_days: 8,
              nr_purchases_all_time: 8,
              purchase_development: null,
              days_since_last_purchase: 4,
              avg_payment_duration: null,
              nr_waiting_for_pickup: 2,
              nr_waiting_for_payment: 0,
              nr_return_all_time: 0.0,
              nps_score: 1.0,
              nps_comment: 'Die Preise dem Sinn einer Auktion anpassen',
              nps_participated_at: '2020-08-31',
              current_nps_category: 'D',
              previous_nps_category: null,
              nr_complaints_last_12_months: null,
              nr_valid_complaints_last_12_months: null,
              complaint_rate_last_12_months: null,
              valid_complaint_rate_last_12_months: null,
            },
          ],
        });
      });
    }
  }
}
