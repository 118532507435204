import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { BaseCRMModule } from '@cosCRMModules/base-crm.module';

import { TransportationProviderScheduleDialogComponent } from './transportation-provider-schedule-dialog.component';

@NgModule({
  imports: [CommonModule, BaseModule, BaseCRMModule],
  declarations: [TransportationProviderScheduleDialogComponent],
  exports: [TransportationProviderScheduleDialogComponent],
})
export class TransportationProviderScheduleDialogModule {}
