import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '../../components/general/base.module';

import { RatingScaleDisplayComponent } from './rating-scale-display.component';

@NgModule({
  imports: [CommonModule, BaseModule],
  declarations: [RatingScaleDisplayComponent],
  exports: [RatingScaleDisplayComponent],
})
export class RatingScaleDisplayModule {}
