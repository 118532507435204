import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { filter, map, Observable } from 'rxjs';
import { EEnzoDialogResult, EnzoDialogService } from '@cos/components/modal-dialogs/enzo-dialog.service';
import { Dialog } from '@angular/cdk/dialog';

export class I18nConfirmationDialogComponent {
  // show a confirm dialog that emits only when it is confirmed
  public static showConfirmDialog(
    dialog: MatDialog | Dialog,
    translationBaseKey: string,
    params?: any,
    width?: string,
    dataCyBase?: string,
  ): Observable<boolean> {
    // if it is not an instance of MatDialog or Dialog, it is probably a mock
    // then it either mimics the layout of the MatDialog object or is a mock of Dialog itself
    const cdkDialog: Dialog = dialog instanceof MatDialog ? (dialog['_dialog'] as Dialog) : dialog instanceof Dialog ? dialog : dialog['_dialog'] || dialog;
    return EnzoDialogService.staticOpenConfirmRejectModal(cdkDialog, translationBaseKey, params, null, width, dataCyBase).pipe(
      filter(result => result === EEnzoDialogResult.CONFIRM),
      map(() => true),
    );
  }

  // show a confirm dialog that emits both on confirmation and on rejection
  public static showConfirmDialogWithRejection(
    dialog: MatDialog | Dialog,
    translationBaseKey: string,
    params?: any,
    width?: string,
    dataCyBase?: string,
  ): Observable<boolean> {
    const cdkDialog: Dialog = dialog instanceof MatDialog ? (dialog['_dialog'] as Dialog) : dialog instanceof Dialog ? dialog : dialog['_dialog'] || dialog;
    return EnzoDialogService.staticOpenConfirmRejectModal(cdkDialog, translationBaseKey, params, null, width, dataCyBase).pipe(
      map(result => result === EEnzoDialogResult.CONFIRM),
    );
  }
}
