import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITransportationProviderDaySchedule, ITransportationProviderTaskView } from '@caronsale/cos-models';
import { CosCoreClient } from '@caronsale/frontend-services';

@Injectable()
export class CosTransportationProviderClientService {
  public constructor(private cosClient: CosCoreClient) {}

  public upsertTransportationTaskMemo(task: ITransportationProviderTaskView) {
    return this.cosClient.requestWithPrivileges(
      'post',
      `/transportation/provider/${this.cosClient.getLastAuthenticationResult().internalUserId}/tasks/${task.id}/memo/`,
      task.memo,
    );
  }

  public getTransportationProviderSchedule(): Observable<ITransportationProviderDaySchedule[]> {
    return this.cosClient.requestWithPrivileges('get', `/transportation/${this.cosClient.getLastAuthenticationResult().internalUserId}/schedule`);
  }

  public upsertTransportationProviderSchedule(days: ITransportationProviderDaySchedule[]): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/transportation/${this.cosClient.getLastAuthenticationResult().internalUserId}/schedule`, days);
  }
}
