import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { CosTransportationProviderClientService } from '@cosTransportServices/cos-transportation-provider-client/cos-transportation-provider-client.service';
import { EDayOfWeek, ITransportationProviderDaySchedule, ModelUtils } from '@caronsale/cos-models';
import { TransportationProviderTranslateService } from '@cosTransportServices/transportation-provider-translate-service/transportation-provider-translate-service';
import { CosInternalClientService } from '@cosCoreServices/cos-internal-client/cos-internal-client.service';
import { DateUtilsService } from '@cosTransportServices/date-utils-service/date-utils-service';

@Component({
  selector: 'app-transportation-provider-schedule-dialog.',
  templateUrl: './transportation-provider-schedule-dialog.component.html',
  styleUrls: ['./transportation-provider-schedule-dialog.component.css'],
})
export class TransportationProviderScheduleDialogComponent implements OnInit {
  public availableHours: string[];
  public busy: boolean;

  public schedule: ITransportationProviderDaySchedule[];

  public constructor(
    public dialogRef: MatDialogRef<TransportationProviderScheduleDialogComponent>,
    private translateService: TranslateService,
    private internalCosClient: CosInternalClientService,
    private dateUtilsService: DateUtilsService,
    private transportationProviderTranslateService: TransportationProviderTranslateService,
    private cosTransportationProviderClientService: CosTransportationProviderClientService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.schedule = [];
    this.availableHours = [];
    this.busy = false;
  }

  public ngOnInit(): void {
    this.dialogRef.updateSize('40%');
    this.busy = true;

    if (this.data && this.data.isAdmin) {
      this.internalCosClient.getTransportationProviderSchedule(this.data.user).subscribe((workingDays: ITransportationProviderDaySchedule[]) => {
        if (workingDays.length > 0) {
          this.schedule = workingDays;
        } else {
          this.fillScheduleWithDaysOfWeek();
        }
        this.busy = false;
      });
    } else {
      this.cosTransportationProviderClientService.getTransportationProviderSchedule().subscribe((workingDays: ITransportationProviderDaySchedule[]) => {
        if (workingDays.length > 0) {
          this.schedule = workingDays;
        } else {
          this.fillScheduleWithDaysOfWeek();
        }
        this.busy = false;
      });
    }
    this.availableHours = this.dateUtilsService.calculateWorkingHoursForADay();
  }

  public save(): void {
    if (this.data && this.data.isAdmin) {
      this.internalCosClient.upsertTransportationProviderSchedule(this.data.user, this.schedule).subscribe(() => {
        this.dialogRef.close();
      });
    } else {
      this.cosTransportationProviderClientService.upsertTransportationProviderSchedule(this.schedule).subscribe(() => {
        this.dialogRef.close();
      });
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getDayName(day: EDayOfWeek): string {
    return this.transportationProviderTranslateService.getWeekDaysTranslated(day);
  }

  private fillScheduleWithDaysOfWeek() {
    for (let i = 0; i <= 6; i++) {
      const daySchedule = ModelUtils.createTransportationProviderDayScheduleSkeleton();
      daySchedule.dayOfWeek = i;
      this.schedule.push(daySchedule);
    }
  }
}
