import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { BaseCRMModule } from './base-crm.module';

import { TransportationProviderScheduleDialogModule } from '@cosTransportComponents/transportation-provider-schedule-dialog/transportation-provider-schedule-dialog.module';
import { TransportationProviderCarouselDialogModule } from '@cosTransportComponents/transportation-provider-carousel-dialog/transportation-provider-carousel-dialog.module';

@NgModule({
  imports: [CommonModule, BaseModule, BaseCRMModule, TransportationProviderScheduleDialogModule, TransportationProviderCarouselDialogModule],
})
export class TransportComponentsModule {}
