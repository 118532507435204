import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { InternalBuyerKpiPopoverComponent } from '@cosCRMComponents/internal-buyer-kpi-popover/internal-buyer-kpi-popover.component';
import { InternalBuyerKpiViewModule } from '@cosCRMComponents/internal-buyer-kpi-view/internal-buyer-kpi-view.module';

@NgModule({
  imports: [CommonModule, BaseModule, InternalBuyerKpiViewModule],
  declarations: [InternalBuyerKpiPopoverComponent],
  exports: [InternalBuyerKpiPopoverComponent],
})
export class InternalBuyerKpiPopoverModule {}
