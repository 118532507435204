import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ETransportationTaskState, ITransportationProviderTaskView, ITransporterUser, ModelUtils } from '@caronsale/cos-models';
import { I18nConfirmationDialogComponent } from '@cos/components/general/i18n/confirmation-dialog/i18n-confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CosInternalAdminClientService } from '@cosCoreServices/cos-internal-admin-client/cos-internal-admin-client.service';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class TransportationTasksManagementService {
  private readonly allowedMimeTypes: string[];

  public constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private adminCosClient: CosInternalAdminClientService,
  ) {
    this.allowedMimeTypes = [...ModelUtils.getDocumentMimeTypes(), ...ModelUtils.getImageMimeTypes()];
  }

  public pickUpTransportationTaskAsAdmin(task: ITransportationProviderTaskView, user: ITransporterUser): Observable<void> {
    // Todo: add texts for this translation key
    return I18nConfirmationDialogComponent.showConfirmDialog(this.dialog, 'confirmDialog.pickupTask').pipe(
      switchMap(() => this.adminCosClient.updateTransportationTaskState(task, ETransportationTaskState.IN_PROGRESS, user)),
    );
  }

  public finishTransportationTaskAsAdmin(task: ITransportationProviderTaskView, user: ITransporterUser): Observable<void> {
    // Todo: add texts for this translation key
    return I18nConfirmationDialogComponent.showConfirmDialog(this.dialog, 'confirmDialog.finishTask').pipe(
      switchMap(() => this.adminCosClient.updateTransportationTaskState(task, ETransportationTaskState.FINISHED, user)),
    );
  }

  public cancelTransportationTaskAsAdmin(task: ITransportationProviderTaskView, user: ITransporterUser) {
    // Todo: add texts for this translation key
    return I18nConfirmationDialogComponent.showConfirmDialog(this.dialog, 'confirmDialog.cancelTask').pipe(
      switchMap(() => this.adminCosClient.unassignTask(user, task)),
    );
  }

  public calculateCountDown(task: ITransportationProviderTaskView, usePickupDueDate: boolean): Date {
    let dueDate;

    if (!usePickupDueDate) {
      const updatedAtDate = new Date(task.lastTaskActivity.updatedAt);
      dueDate = new Date(updatedAtDate.getTime() + 5 * 24 * 60 * 60 * 1000);
    } else {
      const updatedAtDate = new Date(task.pickupDueDate);
      dueDate = new Date(updatedAtDate.getTime());
    }

    return dueDate;
  }

  public checkIsTaskOverdue(task: ITransportationProviderTaskView, usePickupDueDate: boolean): boolean {
    const dueDate = this.calculateCountDown(task, usePickupDueDate);

    return new Date(dueDate) <= new Date();
  }
}
