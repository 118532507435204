import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@cosCoreEnvironments/environment';
import { SentryErrorHandler } from '@sentry/angular';
import { CosCoreClient } from '@caronsale/frontend-services';
import * as Sentry from '@sentry/angular';

@Injectable()
export class SentryGlobalErrorHandler extends SentryErrorHandler implements ErrorHandler {
  public constructor(private cosCoreClient: CosCoreClient) {
    super({
      showDialog: false,
      logErrors: !environment.disableLogging,
    });
  }

  public handleError(error: any): void {
    const generalUser = this.cosCoreClient.getCurrentGeneralUserSnapshot();
    Sentry.setUser({
      name: generalUser?.loginMailAddress, // salesman@random.com, the loginMailAddress
      id: generalUser?.['id'], // 845, the "id" number to find the user in the CRM
      email: generalUser?.mailAddress, // the user's email to find him in the CRM
      uuid: generalUser?.uuid, // the uuid for the backend
    });

    const chunkFailedMessage = /Loading chunk \S+ failed/;
    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload();
    }

    super.handleError(error);
  }
}
