import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { CosAuthService } from '@cosCoreServices/cos-auth/cos-auth.service';
import { CosCoreClient } from '@caronsale/frontend-services';

export function authGuard(): CanActivateFn {
  return (_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    if (inject(CosCoreClient).getLastAuthenticationResult()?.authenticated) {
      return true;
    }
    inject(CosAuthService).saveUrlAndGoToLogin(state.url);
    return false;
  };
}
