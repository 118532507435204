<div class="vehicle-detail-image-container">
  <div>
    <span *ngFor="let image of this.imagesToDisplay; let i = index">
      <div *ngIf="this.currentVehicleImage === i">
        <img class="vehicle-image-carousel-image cursor-pointer" alt="Laden..." [src]="image.url" />
      </div>
    </span>
  </div>
  <span class="vehicle-detail-image-prev" (click)="this.previousImage()">
    <fa-icon icon="chevron-left" size="4x" class="vehicle-detail-image-chevron-left"></fa-icon>
  </span>

  <span class="vehicle-detail-image-next" (click)="this.nextImage()">
    <fa-icon icon="chevron-right" size="4x" class="vehicle-detail-image-chevron-right"></fa-icon>
  </span>
</div>
