import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { InternalBuyerRfmScoreComponent } from '@cosCRMComponents/internal-buyer-rfm-score/internal-buyer-rfm-score.component';
import { InternalCRMPipesModule } from '@caronsale/frontend-pipes';

@NgModule({
  imports: [CommonModule, BaseModule, InternalCRMPipesModule],
  declarations: [InternalBuyerRfmScoreComponent],
  exports: [InternalBuyerRfmScoreComponent],
})
export class InternalBuyerRfmScoreModule {}
