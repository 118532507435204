import { Component } from '@angular/core';
import { IBuyerKpiData } from '@cosCoreServices/cos-bi-service-client/cos-bi-service-client.service';
import { PopoverRef } from '@cosCoreFeatures/@cos/cos-popover/components/popover-ref.component';

@Component({
  selector: 'app-internal-buyer-kpi-view',
  templateUrl: './internal-buyer-kpi-view.component.html',
})
export class InternalBuyerKpiViewComponent {
  public buyerKpi: IBuyerKpiData[];

  public latestKpi: IBuyerKpiData;

  public constructor(private popoverRef: PopoverRef) {
    this.buyerKpi = this.buyerKpi || this.popoverRef.data.buyerKpi;
    if (this.buyerKpi.length > 0) {
      this.latestKpi = this.buyerKpi[0];
    } else {
      this.latestKpi = null;
    }
  }
}
