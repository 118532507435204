import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-internal-buyer-rfm-score',
  templateUrl: './internal-buyer-rfm-score.component.html',
})
export class InternalBuyerRfmScoreComponent implements OnInit {
  @Input()
  public rfmScore: number;

  public recency: number;

  public frequency: number;

  public monetaryValue: number;

  public ngOnInit(): void {
    const rfmScoreAsString = this.rfmScore.toString();

    this.recency = Number(rfmScoreAsString[0]) || null;
    this.frequency = Number(rfmScoreAsString[1]) || null;
    this.monetaryValue = Number(rfmScoreAsString[2]) || null;
  }
}
