<div *ngIf="!latestKpi">Keine Kennzahlen verfügbar</div>
<div *ngIf="latestKpi">
  <span
    *ngIf="latestKpi.avg_rating_last_6_months"
    class="pull-right"
    matTooltip="{{ latestKpi.nr_ratings_last_6_months }} Bewertungen in den letzten 6 Monaten">
    <app-rating-scale-display [size]="5" [value]="latestKpi.avg_rating_last_6_months"> </app-rating-scale-display>
  </span>
  <div class="text-bold margin-bottom-10px">
    <span [ngStyle]="{ 'background-color': latestKpi.rfm_score | buyerKpiSegmentColor, color: '#ffffff', padding: '3px' }">
      {{ latestKpi.rfm_score | buyerKpiSegmentLabel }}
    </span>
  </div>
  <div>
    <span class="text-grey"> Käufe (letzte 30 Tage) </span>
    <span class="pull-right margin-left-10px">
      {{ latestKpi.nr_purchases_last_30_days | valueOrZero }}
      <span *ngIf="latestKpi.purchase_development !== null"> ({{ latestKpi.purchase_development - 1 | percent }}) </span>
    </span>
  </div>
  <div>
    <span class="text-grey"> Gebote (letzte 30 Tage) </span>
    <span class="pull-right margin-left-10px">
      {{ latestKpi.nr_bids_last_30_days | valueOrZero }}
      <span *ngIf="latestKpi.bid_development !== null"> ({{ latestKpi.bid_development - 1 | percent }}) </span>
    </span>
  </div>
  <div>
    <span class="text-grey"> Besuchte Auktionen (letzte 30 Tage) </span>
    <span class="pull-right margin-left-10px">
      {{ latestKpi.nr_views_last_30_days | valueOrZero }}
      <span *ngIf="latestKpi.view_development !== null"> ({{ latestKpi.view_development - 1 | percent }}) </span>
    </span>
  </div>
  <div>
    <span class="text-grey"> Letzter NPS </span>
    <span class="pull-right margin-left-10px">
      {{ latestKpi.nps_score | valueOrQuestionMark }}/10
      <span *ngIf="latestKpi.nps_comment" [matTooltip]="latestKpi.nps_comment" class="far fa-comment"> </span>
    </span>
  </div>
  <div *ngIf="latestKpi.rfm_score">
    <span class="text-grey"> RFM-Score </span>
    <span class="pull-right margin-left-10px">
      <app-internal-buyer-rfm-score [rfmScore]="latestKpi.rfm_score"> </app-internal-buyer-rfm-score>
    </span>
  </div>
  <div *ngIf="latestKpi.days_since_last_view !== null">
    <span class="text-grey"> Letzter Auktionsbesuch: </span>
    <span class="pull-right margin-left-10px">
      vor {{ latestKpi.days_since_last_view | valueOrZero }} Tagen ({{ latestKpi.last_view | date: 'dd.MM.yyyy' }})
    </span>
  </div>
  <div *ngIf="latestKpi.days_since_last_bid !== null">
    <span class="text-grey"> Letztes Gebot: </span>
    <span class="pull-right margin-left-10px">
      vor {{ latestKpi.days_since_last_bid | valueOrZero }} Tagen ({{ latestKpi.last_bid | date: 'dd.MM.yyyy' }})
    </span>
  </div>
  <div *ngIf="latestKpi.days_since_last_purchase !== null">
    <span class="text-grey"> Letzter Kauf: </span>
    <span class="pull-right margin-left-10px">
      vor {{ latestKpi.days_since_last_purchase | valueOrZero }} Tagen ({{ latestKpi.last_purchase | date: 'dd.MM.yyyy' }})
    </span>
  </div>
  <div *ngIf="latestKpi.first_purchase !== null">
    <span class="text-grey"> Erster Kauf: </span>
    <span class="pull-right margin-left-10px"> {{ latestKpi.first_purchase | date: 'dd.MM.yyyy' }} </span>
  </div>
  <div *ngIf="latestKpi.avg_payment_duration">
    <span class="text-grey"> Dauer Fahrzeug-Zahlung: </span>
    <span class="pull-right margin-left-10px">
      <fa-icon *ngIf="latestKpi.avg_payment_duration > 3" matTooltip="Schlechte Zahlungsmoral!" class="text-warn" icon="hourglass"></fa-icon>
      {{ latestKpi.avg_payment_duration | valueOrQuestionMark }} Tag(e)
    </span>
  </div>
  <div *ngIf="latestKpi.gmv_last_12_months !== null">
    <span class="text-grey"> Warenumsatz (letzte 12 Monate) </span>
    <span class="pull-right margin-left-10px"> {{ latestKpi.gmv_last_12_months | currency: 'EUR' }} </span>
  </div>
  <div>
    <span class="text-grey"> Bieten verboten? </span>
    <span class="pull-right margin-left-10px"> {{ latestKpi.is_bidding_prohibited | yesNo }} </span>
  </div>
  <div>
    <span class="text-grey"> Reklamationsrate (letzte 12 Monate) </span>
    <span class="pull-right margin-left-10px"> {{ latestKpi.complaint_rate_last_12_months | valueOrZero | i18nPercent }} </span>
  </div>
  <div>
    <span class="text-grey"> Transportanteil (letzte 12 Monate) </span>
    <span class="pull-right margin-left-10px"> {{ latestKpi.share_of_transportation | valueOrZero | i18nPercent }} </span>
  </div>
  <div>
    <small class="margin-top-10px"> [Daten vom {{ latestKpi.created_at | date: 'dd.MM.yyyy' }}] </small>
  </div>
</div>
