import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IVehicleImage } from '@caronsale/cos-vehicle-models';

@Component({
  selector: 'app-transportation-provider-carousel-dialog',
  templateUrl: './transportation-provider-carousel-dialog.component.html',
  styleUrls: ['./transportation-provider-carousel-dialog.component.scss'],
})
export class TransportationProviderCarouselDialogComponent implements OnInit {
  public busy: boolean;
  public currentVehicleImage: number;
  private maxVehicleImageCounter: number;

  public images: IVehicleImage[];

  public imagesToDisplay: IVehicleImage[];

  public constructor(
    public dialogRef: MatDialogRef<TransportationProviderCarouselDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.busy = false;
    this.currentVehicleImage = 0;
    this.maxVehicleImageCounter = 1;

    this.imagesToDisplay = [];
    this.images = [];
  }

  public ngOnInit(): void {
    this.dialogRef.updateSize('50%');
    this.maxVehicleImageCounter = this.data.task.vehicle.vehicleImages.length;
    this.images = this.data.task.vehicle.vehicleImages;

    this.imagesToDisplay = this.images.filter(image => image && image.url && image.url !== 'NA');
  }

  public nextImage(): void {
    this.currentVehicleImage = (this.currentVehicleImage + 1) % this.maxVehicleImageCounter;
  }

  public previousImage(): void {
    this.currentVehicleImage--;
    if (this.currentVehicleImage < 0) {
      this.currentVehicleImage = this.maxVehicleImageCounter - 1;
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}
