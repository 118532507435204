import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralPipesModule, InternalCRMPipesModule, I18nGeneralPipesModule } from '@caronsale/frontend-pipes';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { InternalBuyerKpiViewComponent } from '@cosCRMComponents/internal-buyer-kpi-view/internal-buyer-kpi-view.component';
import { InternalBuyerRfmScoreModule } from '@cosCRMComponents/internal-buyer-rfm-score/internal-buyer-rfm-score.module';
import { RatingScaleDisplayModule } from '@cos/partials/rating-scale-display/rating-scale-display.module';

@NgModule({
  imports: [
    //
    BaseModule,
    CommonModule,
    GeneralPipesModule,
    I18nGeneralPipesModule,
    InternalBuyerRfmScoreModule,
    InternalCRMPipesModule,
    RatingScaleDisplayModule,
  ],
  declarations: [InternalBuyerKpiViewComponent],
  exports: [InternalBuyerKpiViewComponent],
})
export class InternalBuyerKpiViewModule {}
