import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cookie-law',
  templateUrl: './cookie-law.component.html',
  styleUrls: ['./cookie-law.component.scss'],
  standalone: true,
  imports: [MatButtonModule, TranslateModule],
})
export class CookieLawComponent {
  public constructor(private bottomSheetRef: MatBottomSheetRef<CookieLawComponent>) {}

  public accept() {
    this.bottomSheetRef.dismiss();
  }
}
