import { Injectable } from '@angular/core';
import { EDayOfWeek } from '@caronsale/cos-models';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TransportationProviderTranslateService {
  public constructor(private translateService: TranslateService) {}

  public getWeekDaysTranslated(dayOfWeek: EDayOfWeek): string {
    switch (dayOfWeek) {
      case EDayOfWeek.MONDAY: {
        return this.translateService.instant('common.daysOfTheWeek.monday');
      }
      case EDayOfWeek.TUESDAY: {
        return this.translateService.instant('common.daysOfTheWeek.tuesday');
      }
      case EDayOfWeek.WEDNESDAY: {
        return this.translateService.instant('common.daysOfTheWeek.wednesday');
      }
      case EDayOfWeek.THURSDAY: {
        return this.translateService.instant('common.daysOfTheWeek.thursday');
      }
      case EDayOfWeek.FRIDAY: {
        return this.translateService.instant('common.daysOfTheWeek.friday');
      }
      case EDayOfWeek.SATURDAY: {
        return this.translateService.instant('common.daysOfTheWeek.saturday');
      }
      case EDayOfWeek.SUNDAY: {
        return this.translateService.instant('common.daysOfTheWeek.sunday');
      }
      default:
        break;
    }
  }
}
