import { Component, Input } from '@angular/core';
import { Popover } from '@cosCoreFeatures/@cos/cos-popover/services/popover.service';
import { IBuyerUser } from '@caronsale/cos-models';
import { CosBiServiceClientService, IBiServiceResult, IBuyerKpiData } from '@cosCoreServices/cos-bi-service-client/cos-bi-service-client.service';
import { InternalBuyerKpiViewComponent } from '@cosCRMComponents/internal-buyer-kpi-view/internal-buyer-kpi-view.component';

@Component({
  selector: 'app-internal-buyer-kpi-popover',
  templateUrl: './internal-buyer-kpi-popover.component.html',
})
export class InternalBuyerKpiPopoverComponent {
  @Input()
  public buyerUser: IBuyerUser;

  public kpis: IBuyerKpiData[];

  public constructor(
    private popover: Popover,
    private cosBiServiceClient: CosBiServiceClientService,
  ) {
    this.buyerUser = null;
  }

  public showPopover(event: Event) {
    this.cosBiServiceClient.getBuyerKpis(this.buyerUser.uuid).subscribe((kpiData: IBiServiceResult<IBuyerKpiData>) => {
      this.kpis = kpiData.results.reverse();

      this.popover.open({
        content: InternalBuyerKpiViewComponent,
        data: {
          buyerKpi: this.kpis,
        },
        origin: event.currentTarget as HTMLElement,
      });
    });
  }
}
