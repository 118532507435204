import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating-scale-display',
  templateUrl: './rating-scale-display.component.html',
  styleUrls: ['./rating-scale-display.component.scss'],
})
export class RatingScaleDisplayComponent implements OnInit {
  @Input()
  public size = 5;

  @Input()
  public value = 0;

  public stars: boolean[] = [];

  public ngOnInit(): void {
    for (let i = 1; i <= this.size; i++) {
      this.stars.push(this.value >= i);
    }
  }
}
