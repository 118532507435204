import { NgModule } from '@angular/core';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { CommonModule } from '@angular/common';
import { GeneralPipesModule, I18nGeneralPipesModule } from '@caronsale/frontend-pipes';
import { InternalUserEmailSettingsComponent } from '@cosCRMComponents/internal-user-email-settings/internal-user-email-settings.component';

@NgModule({
  imports: [
    //
    BaseModule,
    CommonModule,
    GeneralPipesModule,
    I18nGeneralPipesModule,
  ],
  declarations: [InternalUserEmailSettingsComponent],
  exports: [InternalUserEmailSettingsComponent],
})
export class InternalUserEmailSettingsModule {}
