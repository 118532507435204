import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPrintModule } from 'ngx-print';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppCRMMaterialModule } from '@cosCRMModules/app-material.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxPrintModule, TranslateModule, NgxSkeletonLoaderModule, AppCRMMaterialModule],
  exports: [NgxPrintModule, TranslateModule, NgxSkeletonLoaderModule, AppCRMMaterialModule],
})
export class BaseCRMModule {}
