import { Injectable } from '@angular/core';

@Injectable()
export class DateUtilsService {
  public calculateWorkingHoursForADay(startHour?: number, endHour?: number): string[] {
    const availableHours = [];

    const begin = startHour || 0;
    const end = endHour || 24;

    for (let i = begin; i <= end; i++) {
      if (i === 0) {
        availableHours.push(`${i}:30`);
        continue;
      }
      if (i === 24) {
        availableHours.push(`${i}:00`);
        continue;
      }
      availableHours.push(`${i}:00`);
      availableHours.push(`${i}:30`);
    }
    return availableHours;
  }
}
