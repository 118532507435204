import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { BaseCRMModule } from '@cosCRMModules/base-crm.module';

import { TransportationProviderCarouselDialogComponent } from './transportation-provider-carousel-dialog.component';

@NgModule({
  imports: [CommonModule, BaseModule, BaseCRMModule],
  declarations: [TransportationProviderCarouselDialogComponent],
  exports: [TransportationProviderCarouselDialogComponent],
})
export class TransportationProviderCarouselDialogModule {}
